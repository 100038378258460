// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bonus-withdrawals__money_imKvq{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;color:var(--BrandDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bonus-withdrawals__money": `bonus-withdrawals__money_imKvq`
};
export default ___CSS_LOADER_EXPORT___;
