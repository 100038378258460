// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tfa-code_DuOgQ{font-size:14px;font-weight:500;line-height:32px;letter-spacing:.25px;color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tfa-code": `tfa-code_DuOgQ`
};
export default ___CSS_LOADER_EXPORT___;
