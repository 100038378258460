import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { v4 as uuidV4 } from 'uuid';
import { Deferred } from '@leon-hub/utils';
import { IconName } from '@leon-hub/icons';
import { JumbotronIconKind } from 'web/src/components/Jumbotron/VJumbotron/enums';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { CaptchaRequesterStrategy } from 'web/src/modules/captcha/store/enums';
import { AnalyticsModalType, DialogAction, DialogComponent, DialogPriority } from '../enums';
const getUpdatedPresetButtons = (preset, buttons)=>{
    if (preset.buttons?.length) {
        const buttonCopy = [
            ...preset.buttons
        ];
        for (const [index, button] of buttonCopy.entries()){
            const updatedFields = buttons[index];
            if (updatedFields) buttonCopy[index] = {
                ...button,
                ...updatedFields
            };
        }
        return buttonCopy;
    }
    return buttons || [];
};
const useDialogsStore = defineStore('dialogs', ()=>{
    const { $translate } = useI18n();
    const analytics = useAnalytics();
    const dialogsList = ref([]);
    const currentDialog = computed(()=>dialogsList.value[0]);
    const hasInnerDialog = ref(false);
    const dialogPresets = computed(()=>{
        const baseCloseButton = {
            action: DialogAction.MODAL_CLOSE,
            label: $translate('JSP_PCL_FBOT_CLOSE').value
        };
        const baseAlertModal = {
            priority: DialogPriority.notification,
            modalComponent: DialogComponent.ConfirmModal,
            iconName: IconName.CHECK_OUTLINE,
            iconKind: JumbotronIconKind.SUCCESS,
            width: ModalWidth.SMALL,
            buttons: [
                baseCloseButton
            ]
        };
        return {
            ALERT_SUCCESS: baseAlertModal,
            ALERT_SUCCESS_NO_BUTTONS: {
                ...baseAlertModal,
                buttons: []
            },
            ALERT_WARNING: {
                ...baseAlertModal,
                priority: DialogPriority.error,
                iconKind: JumbotronIconKind.DEFAULT,
                iconName: IconName.ATTENTION_OUTLINE,
                analyticsModalType: AnalyticsModalType.WARNING
            },
            ALERT_ERROR: {
                ...baseAlertModal,
                priority: DialogPriority.error,
                iconKind: JumbotronIconKind.DEFAULT,
                iconName: IconName.ATTENTION_OUTLINE,
                analyticsModalType: AnalyticsModalType.WARNING,
                title: $translate('JS_CAPTION_ATTENTION').value
            },
            CONFIRM: {
                priority: DialogPriority.default,
                modalComponent: DialogComponent.ConfirmModal,
                iconName: IconName.ATTENTION_OUTLINE,
                buttons: [
                    {
                        action: DialogAction.CONFIRM,
                        label: $translate('LIVECHAT_MODAL_CLOSE_BTN').value
                    },
                    {
                        action: DialogAction.MODAL_CLOSE,
                        kind: ButtonKind.TRANSPARENT,
                        label: $translate('JSPACC_SETT_CANCEL').value
                    }
                ]
            },
            CAPTCHA: {
                priority: DialogPriority.default,
                modalComponent: DialogComponent.CaptchaModal,
                captchaRequesterStrategy: CaptchaRequesterStrategy.DEFAULT,
                buttons: []
            },
            CMS_MODAL: {
                priority: DialogPriority.default,
                fullHeight: true,
                width: ModalWidth.SMALL,
                modalComponent: DialogComponent.CmsContentModal,
                iconName: IconName.ATTENTION_OUTLINE,
                noPadding: true,
                buttons: [
                    {
                        ...baseCloseButton,
                        label: $translate('WEB2_CLOSE_CUSTOMER_NOTIFICATION').value
                    }
                ]
            }
        };
    });
    function resolveDialogPromises(id) {
        for (const item of dialogsList.value)if (!id || item.dialog.id === id) item.promise.resolve();
    }
    function closeDialog(id) {
        resolveDialogPromises(id);
        dialogsList.value = dialogsList.value.filter((item)=>item.dialog.id !== id);
    }
    function closeAllDialogs() {
        resolveDialogPromises();
        dialogsList.value = [];
    }
    function sortDialogs() {
        dialogsList.value.sort((a, b)=>{
            if (a.dialog.priority > b.dialog.priority) return -1;
            if (a.dialog.priority < b.dialog.priority) return 1;
            return a.createdAt - b.createdAt;
        });
    }
    const showDialog = (options)=>{
        const id = options.id || uuidV4();
        const preset = dialogPresets.value[options.presetName];
        let newButtons = preset.buttons ? [
            ...preset.buttons
        ] : [];
        const { buttons, ...dialogOptions } = options.options;
        if (buttons && preset) newButtons = getUpdatedPresetButtons(preset, buttons);
        const item = {
            dialog: {
                ...preset,
                ...dialogOptions,
                buttons: newButtons,
                id,
                priority: options.options.priority ?? DialogPriority.default
            },
            resolvers: {
                [DialogAction.MODAL_DESTROY]: ()=>closeDialog(id)
            },
            promise: new Deferred(),
            createdAt: Date.now()
        };
        const subscribe = (resolvers)=>{
            item.resolvers = {
                ...item.resolvers,
                ...resolvers
            };
        };
        dialogsList.value.push(item);
        sortDialogs();
        if (item.dialog.analyticsModalType) analytics.sendModalErrorMessage(item.dialog);
        return {
            id,
            subscribe,
            promise: item.promise.promise
        };
    };
    function callAction(modalId, action, value) {
        for (const dialog of dialogsList.value)if (dialog.dialog.id === modalId) {
            const doDestroyDialog = dialog.resolvers[action]?.({
                modalId,
                value
            });
            if (false !== doDestroyDialog) dialog.resolvers[DialogAction.MODAL_DESTROY]?.({
                modalId
            });
        }
    }
    function setFullHeight(modalId, fullHeight) {
        for (const dialog of dialogsList.value)if (dialog.dialog.id === modalId) dialog.dialog.fullHeight = fullHeight;
    }
    function updateDialogProps(modalId, newProps) {
        for (const dialog of dialogsList.value)if (dialog.dialog.id === modalId) dialog.dialog = {
            ...dialog.dialog,
            ...newProps
        };
    }
    return {
        currentDialog,
        dialogPresets,
        showDialog,
        closeAllDialogs,
        closeDialog,
        callAction,
        setFullHeight,
        updateDialogProps,
        hasInnerDialog
    };
});
export default useDialogsStore;
