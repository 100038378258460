// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.captcha-margin-bottom_X3sXc{top:50px;display:inline-flex;align-items:center;margin-bottom:10px;overflow:hidden}.captcha-padding_RSn5f{padding:20px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"captcha-margin-bottom": `captcha-margin-bottom_X3sXc`,
	"captcha-padding": `captcha-padding_RSn5f`
};
export default ___CSS_LOADER_EXPORT___;
