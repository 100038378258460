// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.withdrawal-modal__label_ImyX0{color:var(--TextPrimary);display:block;margin:0 0 8px}.withdrawal-modal__label_ImyX0,.withdrawal-modal__label-payment-amount_mrzve{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px}.withdrawal-modal__label-payment-amount_mrzve{margin:0 0 20px}.withdrawal-modal__label-payment-amount-bold_k7CcI{font-size:14px;font-weight:500;line-height:24px;letter-spacing:.25px;display:inline-block;color:var(--TextDefault)}.withdrawal-modal__label-payment-amount-percent_dF9et{display:inline-block}.withdrawal-modal__label-history_cFNNA{margin-bottom:0;-webkit-text-decoration:underline;text-decoration:underline;cursor:pointer}@media(hover:hover)and (pointer:fine){.withdrawal-modal__label-history_cFNNA:hover{color:var(--TextDefault)}}.withdrawal-modal__label-cancel_OHAfs{margin-bottom:0}.withdrawal-modal__label-available-balance_KcTIE{margin-top:20px}.withdrawal-modal__label-balance_rbEfu{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;color:var(--TextDefault);margin:8px 0 0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"withdrawal-modal__label": `withdrawal-modal__label_ImyX0`,
	"withdrawal-modal__label-payment-amount": `withdrawal-modal__label-payment-amount_mrzve`,
	"withdrawal-modal__label-payment-amount-bold": `withdrawal-modal__label-payment-amount-bold_k7CcI`,
	"withdrawal-modal__label-payment-amount-percent": `withdrawal-modal__label-payment-amount-percent_dF9et`,
	"withdrawal-modal__label-history": `withdrawal-modal__label-history_cFNNA`,
	"withdrawal-modal__label-cancel": `withdrawal-modal__label-cancel_OHAfs`,
	"withdrawal-modal__label-available-balance": `withdrawal-modal__label-available-balance_KcTIE`,
	"withdrawal-modal__label-balance": `withdrawal-modal__label-balance_rbEfu`
};
export default ___CSS_LOADER_EXPORT___;
