// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deposit-modal--label_IzvYF{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal;display:block;margin:0 0 20px}.deposit-modal--label-phone_Dzujt{margin-bottom:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deposit-modal--label": `deposit-modal--label_IzvYF`,
	"deposit-modal--label-phone": `deposit-modal--label-phone_Dzujt`
};
export default ___CSS_LOADER_EXPORT___;
