// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.countdown_jLSiB{font-weight:400;color:var(--TextDefault)}.countdown_jLSiB,.countdown__timer_vfMvq{font-size:14px;line-height:24px;letter-spacing:.25px}.countdown__timer_vfMvq{font-weight:500;color:var(--BrandDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"countdown": `countdown_jLSiB`,
	"countdown__timer": `countdown__timer_vfMvq`
};
export default ___CSS_LOADER_EXPORT___;
